import React from "react";

export default class EDRSRIcon extends React.Component {
  static defaultProps = {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "24px",
    transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    userSelect: "none",
    flexShrink: 0,
  };

  render() {
    return (
      <svg
        fill={this.props.fill}
        width={this.props.width}
        height={this.props.height}
        fontSize={this.props.fontSize}
        display={this.props.display}
        transition={this.props.transition}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 193 191"
      >
        <path
          d="M63.2609 41.6623C63.2609 39.2895 64.2034 37.014 65.8812 35.3362L98.597 2.62035C100.275 0.942569 102.55 0 104.923 0H107.543C111.037 0 113.87 2.83229 113.87 6.32609V6.32609C113.87 9.81989 116.702 12.6522 120.196 12.6522V12.6522C123.689 12.6522 126.522 15.4845 126.522 18.9783V18.9783C126.522 22.4721 129.354 25.3043 132.848 25.3043H144.415C149.16 25.3043 153.711 27.1895 157.067 30.545L158.152 31.6304L159.238 32.7158C162.593 36.0714 164.478 40.6225 164.478 45.368V56.9348C164.478 60.4286 167.311 63.2609 170.804 63.2609V63.2609C174.298 63.2609 177.13 66.0932 177.13 69.587V69.587C177.13 73.0808 179.963 75.913 183.457 75.913V75.913C186.95 75.913 189.783 78.7453 189.783 82.2391V84.8595C189.783 87.2322 188.84 89.5078 187.162 91.1856L154.446 123.901C152.769 125.579 150.493 126.522 148.12 126.522H145.5C142.006 126.522 139.174 123.689 139.174 120.196V120.196C139.174 116.702 136.342 113.87 132.848 113.87V113.87C129.354 113.87 126.522 111.037 126.522 107.543V107.543C126.522 104.05 123.689 101.217 120.196 101.217H113.87H109.956C104.41 101.217 99.1399 103.641 95.5303 107.852L29.6366 184.728C26.8869 187.936 22.8726 189.783 18.6474 189.783V189.783C14.8087 189.783 11.1273 188.258 8.41292 185.543L6.32609 183.457L4.23925 181.37C1.5249 178.655 0 174.974 0 171.135V171.135C0 166.91 1.84631 162.896 5.05435 160.146L82.5126 93.7532C86.3542 90.4603 88.5652 85.6532 88.5652 80.5934V69.587C88.5652 66.0932 85.7329 63.2609 82.2391 63.2609V63.2609C78.7453 63.2609 75.913 60.4286 75.913 56.9348V56.9348C75.913 53.441 73.0808 50.6087 69.587 50.6087V50.6087C66.0932 50.6087 63.2609 47.7764 63.2609 44.2826V41.6623Z"
          fill="#CCD4DC"
        />
        <path
          d="M77.913 167.478C77.913 165.821 79.2561 164.478 80.913 164.478H87.5652C89.222 164.478 90.5652 163.135 90.5652 161.478V154.826C90.5652 153.169 91.9083 151.826 93.5652 151.826H176.13C177.787 151.826 179.13 153.169 179.13 154.826V161.478C179.13 163.135 180.474 164.478 182.13 164.478H188.783C190.439 164.478 191.783 165.821 191.783 167.478V186.783C191.783 188.439 190.439 189.783 188.783 189.783H80.913C79.2561 189.783 77.913 188.439 77.913 186.783V167.478Z"
          fill="#CCD4DC"
        />
        <path
          d="M117.146 18.8541C117.379 18.6212 117.663 18.4458 117.975 18.3416V18.3416C119.633 17.7889 121.211 19.3666 120.658 21.0249V21.0249C120.554 21.3373 120.379 21.6212 120.146 21.8541L86.3079 55.6921C86.1047 55.8953 85.8625 56.055 85.5957 56.1617V56.1617C83.8641 56.8544 82.1456 55.1359 82.8383 53.4043V53.4043C82.945 53.1375 83.1047 52.8953 83.3079 52.6921L117.146 18.8541Z"
          fill="#00274E"
        />
        <path
          d="M117.146 18.8541C117.379 18.6212 117.663 18.4458 117.975 18.3416V18.3416C119.633 17.7889 121.211 19.3666 120.658 21.0249V21.0249C120.554 21.3373 120.379 21.6212 120.146 21.8541L86.3079 55.6921C86.1047 55.8953 85.8625 56.055 85.5957 56.1617V56.1617C83.8641 56.8544 82.1456 55.1359 82.8383 53.4043V53.4043C82.945 53.1375 83.1047 52.8953 83.3079 52.6921L117.146 18.8541Z"
          fill="#00274E"
        />
        <path
          d="M167.146 70.8541C167.379 70.6212 167.663 70.4458 167.975 70.3416V70.3416C169.633 69.7889 171.211 71.3666 170.658 73.0249V73.0249C170.554 73.3373 170.379 73.6212 170.146 73.8541L136.308 107.692C136.105 107.895 135.862 108.055 135.596 108.162V108.162C133.864 108.854 132.146 107.136 132.838 105.404V105.404C132.945 105.138 133.105 104.895 133.308 104.692L167.146 70.8541Z"
          fill="#00274E"
        />
        <path
          d="M167.146 70.8541C167.379 70.6212 167.663 70.4458 167.975 70.3416V70.3416C169.633 69.7889 171.211 71.3666 170.658 73.0249V73.0249C170.554 73.3373 170.379 73.6212 170.146 73.8541L136.308 107.692C136.105 107.895 135.862 108.055 135.596 108.162V108.162C133.864 108.854 132.146 107.136 132.838 105.404V105.404C132.945 105.138 133.105 104.895 133.308 104.692L167.146 70.8541Z"
          fill="#00274E"
        />
      </svg>
    );
  }
}
