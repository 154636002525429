import store from "../../store";
import config from "../../config";

const LMK = 0;
const MMK = 1;

/**
 * @param {MouseEvent} e
 */

/**
 * @param {MouseEvent} e
 */
export const handleEDRSRClick = async (e) => {
  let target = "_blank";

  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();

  const host = config().edrsrRedirectUrl;
  if (!host) return;
  const { token } = store.getState().authorization || {};
  if (!token) return;

  window.open(`${host}?token=${token}`, target);
};

/**
 * @param {MouseEvent} e
 */
export const handleEDRSRDEBUGClick = async (e) => {
  let target = "_blank";

  if (e.button !== LMK && e.button !== MMK) {
    return;
  }

  if (e.button === MMK || (e.button === LMK && (e.ctrlKey || e.metaKey))) {
    target = "_blank";
  }

  e.preventDefault();

  const host = config().edrsrRedirectUrlDEBUG;
  if (!host) return;
  const { token } = store.getState().authorization || {};
  if (!token) return;

  window.open(`${host}?token=${token}`, target);
};
